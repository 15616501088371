<template>
  <div class="flex items-center justify-between h-full w-full overflow-hidden relative" @click.stop="" @mousedown.stop="">
    <div class="flex items-center h-full w-full justify-start flex-shrink-0">
      <yb-icon v-if="icon" :icon="icon" class="w-5 h-5 mx-1 inline-block flex-shrink-0" />
      <div class="text-base truncate !leading-8">
        {{ grantee }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  computed: {
    grantee() {
      return this.params.data.grantee
    },
    icon() {
      const { grantee } = this
      if (grantee === 'PUBLIC') {
        return 'share'
      } else {
        return this.params.data.role?.type || 'role'
      }
    },
    is_editable() {
      return !!this.params?.is_editable
    }
  }
}
</script>
