<template>
  <div class="flex flex-col space-y-1.5">
    <template v-for="privilege_type in allPrivilegeTypes" :key="privilege_type">
      <div class="font-semibold flex flex-row items-center justify-between select-none">
        <div>{{ capitalize(privilege_type) }}</div>
        <yb-role-selector
          v-show="privilege_type === 'ALL' || (!!selfPrivilegesMap[privilege_type] && selfPrivilegesMap[privilege_type].is_grantable)"
          v-tooltip="'GRANT ' + privilege_type"
          :roles="manageableRoles"
          :show-with-grant-option="true"
          @add="grantRole(privilege_type, $event.name, arguments[1])"
        />
      </div>

      <div class="flex flex-row flex-wrap items-center ml-4 pb-1">
        <template v-for="(privilege, index) in (storedPrivilegesMap[privilege_type] || [])">
          <yb-privilege-chiclet
            v-if="!privilege.revoke && !owners.has(privilege.grantee) && privilege.grantee != ownername && privilege.grantee != storedOwnername"
            :id="privilege.grantee"
            :key="index"
            :label="privilege.grantee"
            :icon="privilege.grantee == 'PUBLIC' ? '' : (rolesMap[privilege.grantee] && rolesMap[privilege.grantee].type)"
            :removable="privilege_type == 'ALL' || privilege.grantee == 'PUBLIC' || (!!selfPrivilegesMap[privilege_type] && selfPrivilegesMap[privilege_type].is_grantable)"
            :remove="privilege.revoke"
            :shareable="privilege_type == 'ALL' || (privilege.grantee != 'PUBLIC' && !!selfPrivilegesMap[privilege_type] && selfPrivilegesMap[privilege_type].is_grantable)"
            :shared="privilege.is_grantable"
            :editable="!!selfPrivilegesMap[privilege_type] && selfPrivilegesMap[privilege_type].is_grantable && (privilege.grantee == 'PUBLIC' || (!!rolesMap[privilege.grantee] && rolesMap[privilege.grantee].id >= 16384 && !privilege.grantee.match(/^sys_ybd_/)))"
            class="ml-2 mb-2"
            @remove="toggleRevoke(privilege.grantee, privilege_type)"
            @share="toggleWithGrant(privilege, $event)"
          />
        </template>
      </div>
      <div class="h-0.5 border-t yb-border-dialog" />
    </template>
  </div>
</template>

<script>
import YbPrivilegeChiclet from './PrivilegeChiclet.vue'
import YbRoleSelector from './RoleSelector.vue'
import { capitalize } from '@/filters'

export default {
  components: {
    YbPrivilegeChiclet,
    YbRoleSelector
  },
  props: [
    'allPrivilegeTypes',
    'storedPrivilegesMap',
    'selfPrivilegesMap',
    'rolesMap',
    'storedPrivileges',
    'manageableRoles',
    'ownername',
    'storedOwnername',
    'owners',
    'grantRole',
    'grantAll',
    'revokeAll',
    'toggleRevoke',
    'toggleWithGrant'
  ],
  methods: {
    capitalize
  }
}
</script>
