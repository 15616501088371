<template>
  <div class="flex items-center justify-center w-full h-full">
    <div
      v-tooltip="!is_editable ? 'You do not have privileges to modify this privilege' : 'Grant this privilege'"
      class="h-7 w-7 flex items-center justify-center"
      :class="grantClasses"
      @click="grantChanged(!editState.granted)"
    >
      <yb-icon
        v-if="editState.granted"
        icon="check"
        class="dark:!text-black yb-button-icon-md ml-1 mr-1 mt-0.5"
        :class="!is_editable ? 'opacity-80' : ''"
      />
    </div>
    <div
      v-if="grantableShown"
      v-tooltip="!is_editable ? 'You do not have privileges to modify this privilege' : 'Allow this privilege to be granted to other user/roles (WITH GRANT OPTION)'"
      class="h-7 w-7 flex items-center justify-center border rounded-tr rounded-br"
      :class="grantableClasses"
      @click="isGrantableChanged(!editState.is_grantable)"
    >
      <yb-icon
        icon="share"
        class="dark:!text-black yb-button-icon-md ml-1 mr-1 mt-0.5"
        :class="!is_editable || !editState.granted ? 'opacity-80' : ''"
      />
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      editState: {}
    }
  },
  computed: {
    is_editable() {
      return !!this.params?.is_editable
    },
    self_is_grantable() {
      return !!this.params?.self_is_grantable
    },
    grantableShown() {
      const { is_editable, self_is_grantable, editState: { granted } } = this
      return is_editable && self_is_grantable && granted
    },
    grantClasses() {
      const result = []
      const { is_editable, grantableShown, editState: { granted, is_grantable } } = this
      if (!is_editable) {
        result.push(...['border-yb-gray-lighter', 'bg-yb-gray-alt-lightest', 'bg-opacity-80', 'dark:bg-opacity-50'])
      } else {
        result.push('border-yb-brand-primary')
        if (granted) {
          result.push('bg-yb-rollover')
        } else {
          result.push(...['bg-white', 'dark:bg-yb-gray-light'])
        }
      }
      if (grantableShown) {
        result.push(...['border-t', 'border-b', 'border-l', 'rounded-tl', 'rounded-bl'])
      } else {
        result.push(...['border', 'rounded'])
      }
      return result
    },
    grantableClasses() {
      const result = []
      const { is_editable, self_is_grantable, editState: { granted, is_grantable } } = this
      if (!is_editable || !self_is_grantable) {
        result.push(...['border-yb-gray-lighter', 'bg-yb-gray-alt-lightest', 'bg-opacity-80', 'dark:bg-opacity-50'])
      } else {
        result.push('border-yb-brand-primary')
        if (is_grantable) {
          result.push('bg-yb-rollover')
        } else {
          result.push(...['bg-white', 'dark:bg-yb-gray-light'])
        }
      }
      return result
    }
  },
  mounted() {
    this.editState = this.params?.value?.editState
  },
  methods: {
    grantChanged(value) {
      if (this.is_editable) {
        this.params.grantChanged(this.params.value, value)
        this.editState.granted = value
      }
    },
    isGrantableChanged(value) {
      if (!!this.is_editable && !!this.self_is_grantable) {
        this.params.isGrantableChanged(this.params.value, value)
        this.editState.is_grantable = value
      }
    }
  }
}
</script>
