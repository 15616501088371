<template>
  <div class="yb-view grid-view">
    <div class="yb-view-header flex flex-row items-end py-2 pl-1 space-x-2">
      <yb-search-input v-model="searchExpression" placeholder="Search Users/Roles" input-classes="w-full py-1 px-2 yb-input-base" />
      <yb-role-selector
        v-tooltip="'Grant privileges to a user/role'"
        icon="plus_circle"
        label="Add User/Role"
        data-test-id="add-user-role"
        :show-arrow="true"
        :show-border="true"
        :roles="manageableRolesNotGranted"
        @add="add"
      />

      <yb-drop-down-button
        action-link-base-classes="py-1"
        label="Actions"
        icon="action"
        max-height="h-auto"
        action-link
        :disabled="!editable"
      >
        <yb-drop-down-item v-if="editable" label="Grant ALL privileges" @click.prevent="grantAllSelected" />
        <yb-drop-down-item v-if="editable" label="Grant ALL privileges WITH GRANT OPTION" @click.prevent="grantAllWithGrantOptionSelected" />
        <hr>
        <yb-drop-down-item v-if="editable" label="Revoke ALL privileges" @click.prevent="revokeAllSelected" />
      </yb-drop-down-button>
    </div>
    <yb-grid
      ref="grid"
      class="yb-view-content pb-8"
      :rows="model"
      :column-defs="columnDefs"
      column-id="grantee"
      :search-expression="searchExpression"
      no-rows-to-show="No roles to display"
      :header-height="80"
      :row-height="50"
      @notready="ready"
      @select="select"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import YbRoleGridRendererComponent from './RoleGridRenderer.vue'
import YbPrivilegeGridRendererComponent from './PrivilegeGridRenderer.vue'
import YbRoleSelector from './RoleSelector.vue'
import { capitalize } from '@/filters'
import * as functions from '@/util/functions'

const YbRoleGridRenderer = defineComponent(YbRoleGridRendererComponent)
const YbPrivilegeGridRenderer = defineComponent(YbPrivilegeGridRendererComponent)

export default {
  components: {
    YbRoleSelector
  },
  props: [
    'allPrivilegeTypes',
    'storedPrivilegesMap',
    'selfPrivilegesMap',
    'rolesMap',
    'storedPrivileges',
    'manageableRoles',
    'ownername',
    'storedOwnername',
    'owners',
    'grantRole',
    'grantAll',
    'revokeAll',
    'toggleRevoke',
    'toggleWithGrant'
  ],
  data() {
    const vm = this
    return {
      selectedObject: null,
      searchExpression: '',
      editStates: {},
      columnDefs: [
        {
          headerName: 'Role',
          field: 'grantee',
          maxWidth: 200,
          maxWidth: 300,
          flex: 2,
          sortable: true,
          pinned: true,
          cellClass: 'role-column',
          cellRenderer: 'agGroupCellRenderer',
          headerCheckboxSelection: false,
          cellRendererParams: {
            checkbox: true,
            innerRenderer: YbRoleGridRenderer,
            innerRendererParams() {
              const { value: grantee } = _
              return {
                is_editable: vm.isEditable(grantee)
              }
            }
          }
        }
      ].concat(...this.allPrivilegeTypes.map((privilege_type) => {
        return {
          headerName: capitalize(privilege_type),
          field: privilege_type,
          minWidth: 100,
          sortable: false,
          headerClass: 'privilege-header',
          cellClass: 'yb-cell-disable-select-emphasis',
          cellRenderer: YbPrivilegeGridRenderer,
          cellRendererParams(_) {
            const { selfPrivilegesMap, rolesMap } = vm
            const { value: { privilege_type, grantee } } = _
            return {
              self_is_grantable: grantee !== 'PUBLIC' && selfPrivilegesMap[privilege_type] && selfPrivilegesMap[privilege_type].is_grantable,
              is_editable: (selfPrivilegesMap[privilege_type] && selfPrivilegesMap[privilege_type].is_grantable) && (grantee === 'PUBLIC' || (!!rolesMap[grantee] && rolesMap[grantee].id >= 16384 && !grantee.match(/^sys_ybd_/))),
              grantChanged(privilege, grant) {
                if (grant) {
                  vm.grantRole(privilege.privilege_type, privilege.grantee, false)
                } else {
                  vm.toggleRevoke(privilege.grantee, privilege.privilege_type)
                }
              },
              isGrantableChanged(privilege, is_grantable) {
                vm.toggleWithGrant(privilege, is_grantable)
              }
            }
          }
        }
      }))
    }
  },
  computed: {
    model() {
      const storedRolePrivilegesMap = functions.groupBy(this.storedPrivileges, 'grantee')
      const { rolesMap, owners, ownername, username, storedOwnername, selfPrivilegesMap } = this
      const result = Object.entries(storedRolePrivilegesMap)
        .filter(([grantee, privileges]) => {
          return !owners.has(grantee) && grantee !== ownername && grantee !== storedOwnername && grantee !== username
        })
        .map(([grantee, privileges]) => {
          const allPrivilegeTypesFlattened = this.allPrivilegeTypes.reduce((flattened, privilege_type) => {
            flattened[privilege_type] = {
              grantee,
              privilege_type,
              is_grantable: false,
              starting_is_grantable: false,
              grant: false,
              revoke: false,
              role: rolesMap[grantee],
              editState: { granted: false, is_grantable: false }
            }
            return flattened
          }, {})
          const privilegesFlattened = functions.indexBy(privileges.map((privilege) => {
            const key = `${privilege.grantee}:${privilege.privilege_type}`
            let editState = this.editStates[key]
            if (!editState) {
              editState = { granted: !!selfPrivilegesMap[privilege.privilege_type]?.is_grantable, is_grantable: privilege.is_grantable }
              this.editStates[key] = editState
            }
            return {
              ...privilege,
              ...{
                editState,
                role: rolesMap[grantee]
              }
            }
          }), 'privilege_type')
          return { ...{ grantee }, ...allPrivilegeTypesFlattened, ...privilegesFlattened }
        })
      return result
    },
    grantee() {
      return this.selectedObject?.grantee
    },
    editable() {
      const { grantee } = this
      return !!grantee && this.isEditable(grantee)
    },
    username() {
      return this.$store.get('global/settings@user.email')
    },
    manageableRolesNotGranted() {
      const grantedRoleSet = new Set(this.storedPrivileges.map(p => p.grantee))
      grantedRoleSet.add(this.ownername)
      grantedRoleSet.add(this.username)
      return this.manageableRoles
        .filter(r => !grantedRoleSet.has(r.name))
    }
  },
  methods: {
    isEditable(grantee) {
      const { rolesMap } = this
      return grantee === 'PUBLIC' || (!!rolesMap[grantee] && rolesMap[grantee].id >= 16384 && !grantee.match(/^sys_ybd_/))
    },
    ready() {
      this.$refs.grid.gridColumnApi.applyColumnState({
        state: [
          {
            colId: 'grantee',
            sort: 'asc'
          }
        ],
        defaultState: { sort: null }
      })
      this.$refs.grid.gridColumnApi.autoSizeColumns()
    },
    add(role) {
      this.grantRole('PLACEHOLDER', role.name, false)
    },
    select(items) {
      if (!!items && !!items.length) {
        [this.selectedObject] = items
      } else {
        this.selectedObject = null
      }
    },
    grantAllSelected() {
      const { grantee, model } = this
      !!grantee && this.grantAll({ name: grantee }, false)
      const item = model.find(m => m.grantee === grantee)
      if (item) {
        const { selfPrivilegesMap } = this
        Object.entries(item).forEach(([privilege_type, { editState }]) => {
          if (editState) {
            const grantable = !!selfPrivilegesMap[privilege_type]?.is_grantable
            editState.granted = grantable
          }
        })
      }
    },
    grantAllWithGrantOptionSelected() {
      const { grantee, model } = this
      !!grantee && this.grantAll({ name: grantee }, true)
      const item = model.find(m => m.grantee === grantee)
      if (item) {
        const { selfPrivilegesMap } = this
        Object.entries(item).forEach(([privilege_type, { editState }]) => {
          if (editState) {
            const grantable = !!selfPrivilegesMap[privilege_type]?.is_grantable
            editState.granted = grantable
            editState.is_grantable = grantable
          }
        })
      }
    },
    revokeAllSelected() {
      const { grantee } = this
      !!grantee && this.revokeAll({ name: grantee })
      this.$refs.grid.deselectAll()
    }
  }
}
</script>

<style lang="postcss" scoped>
div.grid-view :deep(.ag-theme-alpine) div.privilege-header .ag-header-cell-text {
  @apply whitespace-normal text-center;
}

div.grid-view :deep(.ag-theme-alpine) .ag-header-cell-label {
  @apply flex flex-row justify-center items-center;
}

div.grid-view :deep(.role-column) {
  @apply pl-4 pr-4;
}

div.grid-view :deep(.ag-group-checkbox) {
  @apply w-4 mr-2;
}
</style>
