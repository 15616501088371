<template>
  <div class="yb-view object-editor">
    <div class="yb-view-header relative pb-8">
      <div v-if="hasSchemaPrivilegesChange" class="absolute right-0 top-0 flex flex-col items-end justify-end space-y-1">
        <yb-sql class="" height-class="max-h-48" width-class="max-w-500" :sql="getSchemaPrivilegesSql.bind(this)" />
      </div>
      <div v-else-if="changeCount > 0" class="absolute right-0 top-0 flex flex-col items-end justify-end space-y-1">
        <yb-sql class="" height-class="max-h-48" width-class="max-w-500" :sql="getSql.bind(this)" />
        <div class="text-sm font-light">
          {{ changeCount }} change(s) pending
        </div>
      </div>
      <div v-tooltip="effectiveLabel" class="text-lg whitespace-nowrap truncate w-full pr-20">
        <template v-if="showSchemaPrivileges && !!schema">
          <yb-icon icon="schema" class="yb-button-icon" />
          Privileges for schema <span class="font-semibold">{{ schema }}</span>
        </template>
        <template v-else>
          <yb-icon :icon="icon || type" class="yb-button-icon" />
          Privileges for <template v-if="targets.length > 1">
            ({{ targets.length }})
          </template>{{ type }}<template v-if="targets.length > 1">
            s
          </template> <span class="font-semibold">{{ effectiveLabel }}</span>
        </template>
      </div>
    </div>
    <div v-if="loading" class="yb-view-content flex items-center justify-center h-full">
      <yb-loading />
    </div>
    <div v-else-if="showSchemaPrivileges" class="yb-view-content flex flex-col px-2 space-y-6">
      <div class="flex flex-col space-y-1">
        <label>Grant or revoke object privileges in schema</label>
        <div class="flex flex-row pl-4 space-x-2">
          <yb-button label="Grant" class="yb-button-invert-base yb-button-base-lg border-yb-brand-primary font-light text-black" :class="schemaPrivileges.action === 'grant' ? 'bg-yb-selection-light !text-yb-gray-medium' : 'dark:text-white'" data-test-id="object-privileges-schema-grant" @click="schemaPrivileges.action = 'grant'" />
          <yb-button label="Revoke" class="yb-button-invert-base yb-button-base-lg border-yb-brand-primary font-light text-black" :class="schemaPrivileges.action === 'revoke' ? 'bg-yb-selection-light !text-yb-gray-medium' : 'dark:text-white'" data-test-id="object-privileges-schema-revoke" @click="schemaPrivileges.action = 'revoke'" />
        </div>
      </div>

      <div v-if="schemaPrivileges.action" class="flex flex-col space-y-1">
        <label>Choose which object types in schema for {{ schemaPrivileges.action }}?</label>

        <yb-material-select
          v-model="schemaPrivileges.type"
          class="w-full"
          width-class="w-auto"
          :options="schemaObjectTypes"
          label=" "
          item-id="value"
          item-label="label"
          :search-limit="20"
          data-test-id="object-privileges-schema-type"
          help="Specify which type of action to adjust privileges for"
        />
      </div>

      <div v-if="schemaPrivileges.type" class="flex flex-col space-y-1">
        <label>Which privilege on {{ schemaPrivileges.type }} objects for {{ schemaPrivileges.action }}?</label>

        <yb-material-select
          v-model="schemaPrivileges.privilege"
          class="w-full"
          width-class="w-auto"
          :options="schemaObjectPrivileges"
          label=" "
          item-id="value"
          item-label="label"
          :search-limit="20"
          data-test-id="object-privileges-schema-privilege"
          help="Specify which privilege to adjust"
        />
      </div>

      <div v-if="schemaPrivileges.privilege" class="flex flex-col space-y-1">
        <label>Which role to {{ schemaPrivileges.action }} for {{ schemaPrivileges.type }} objects?</label>

        <yb-material-select
          v-model="schemaPrivileges.role"
          class="w-full"
          width-class="w-auto"
          :options="manageableRoles"
          label=" "
          item-id="name"
          item-label="name"
          :search-limit="20"
          data-test-id="object-privileges-schema-role"
          help="Specify which role for grant/revoke"
        />
      </div>

      <div class="pt-32">
        <a v-if="showSchemaPrivileges" class="yb-link" data-test-id="object-privileges-back" @click="showSchemaPrivileges = false">&lt; Back</a>
      </div>
    </div>
    <div v-else-if="!showDebug" class="yb-view-content-auto pt-4 px-4 pb-4 flex flex-col space-y-1.5 border-t yb-border-dialog ">
      <div class="flex flex-row items-center justify-between pb-4">
        <div class="flex flex-row items-center">
          <span class="font-semibold">Manage By</span>
          <yb-button-group
            v-model="privilegesManageBy"
            label=""
            class="ml-2 text-sm whitespace-nowrap"
            :vertical="false"
            btn-down="btn-yellow"
            type="id"
            :options="[
              {
                id: 'role',
                label: 'Users/Roles'
              },
              {
                id: 'privilege',
                label: 'Privilege'
              },
            ]"
          />
        </div>
        <a v-if="type == 'schema' || !!schema" class="yb-link" data-test-id="object-privileges-schema" @click="manageSchemaPrivileges">
          Manage Schema Object Privileges {{ (schema ? ' in ' + schema: '') }}
        </a>
      </div>

      <div
        class="flex flex-row flex-nowrap items-center justify-between"
        :class="(hasOwner && type !== 'cluster') || (privilegesManageBy === 'privilege') ? 'pb-4 border-b yb-border-dialog mb-4' : ''"
      >
        <div class="flex flex-row flex-nowrap items-center space-x-2">
          <template v-if="hasOwner && type !== 'cluster'">
            <span><span class="font-semibold inline-block mr-2">Owner</span>{{ ownername }}</span>
            <yb-role-selector
              v-tooltip="'Change ownership'"
              icon="edit"
              label="Change"
              :show-arrow="true"
              :show-border="true"
              :roles="manageableRolesNoPublic"
              @add="changeOwner($event)"
            />
          </template>
        </div>
        <div v-if="privilegesManageBy === 'privilege' && hasGrantablePrivileges" class="flex flex-row flex-nowrap items-center space-x-2">
          <yb-role-selector
            v-tooltip="'Grant ALL privileges to a user/role'"
            icon="plus_circle"
            label="Grant ALL"
            :show-arrow="true"
            :show-border="true"
            :show-with-grant-option="true"
            :roles="manageableRoles"
            @add="grantAll($event, arguments[1])"
          />
          <yb-role-selector
            v-tooltip="'Revoke ALL privileges from a user/role'"
            icon="delete_circle"
            label="Revoke ALL"
            :show-arrow="true"
            :show-border="true"
            :roles="manageableRoles"
            @add="revokeAll($event)"
          />
        </div>
      </div>

      <tabs v-if="privilegeCategorization" class="h-full">
        <tab v-for="(category, index) in privilegeCategorization" :key="index" :title="category.label">
          <component
            :is="editorComponent"
            :all-privilege-types="category.privileges"
            :stored-privileges-map="storedPrivilegesMap"
            :self-privileges-map="selfPrivilegesMap"
            :roles-map="rolesMap"
            :stored-privileges="storedPrivileges"
            :manageable-roles="manageableRoles"
            :ownername="ownername"
            :stored-ownername="storedOwnername"
            :owners="effectiveOwners"
            :grant-role="grantRole"
            :grant-all="grantAll"
            :revoke-all="revokeAll"
            :toggle-revoke="toggleRevoke"
            :toggle-with-grant="toggleWithGrant"
          />
        </tab>
      </tabs>

      <component
        :is="editorComponent"
        v-else
        :all-privilege-types="allPrivilegeTypes"
        :stored-privileges-map="storedPrivilegesMap"
        :self-privileges-map="selfPrivilegesMap"
        :roles-map="rolesMap"
        :stored-privileges="storedPrivileges"
        :manageable-roles="manageableRoles"
        :ownername="ownername"
        :stored-ownername="storedOwnername"
        :owners="effectiveOwners"
        :grant-role="grantRole"
        :grant-all="grantAll"
        :revoke-all="revokeAll"
        :toggle-revoke="toggleRevoke"
        :toggle-with-grant="toggleWithGrant"
      />
    </div>

    <div v-else-if="showDebug" class="yb-view-content-auto p-4">
      <div class="h-0.5 border-b yb-border-dialog  pt-8" />
      <div class="font-semibold">
        Stored Privileges
      </div>
      <table class="w-full">
        <thead>
          <tr>
            <th>Grantee</th>
            <th>Privilege</th>
            <th>Is Grantable</th>
          </tr>
        </thead>
        <tr v-for="(p, index) in storedPrivileges" :key="index">
          <td>{{ p.grantee }}</td>
          <td>{{ p.privilege_type }}</td>
          <td class="text-center">
            {{ p.is_grantable ? '&check;' : '' }}
          </td>
        </tr>
      </table>

      <div class="h-0.5 border-b yb-border-dialog  pt-8" />
      <div class="font-semibold">
        Self Privileges
      </div>
      <table class="w-full">
        <thead>
          <tr>
            <th>Privilege</th>
            <th>Granted</th>
            <th>Is Grantable</th>
          </tr>
        </thead>
        <tr v-for="(p, index) in selfPrivileges" :key="index">
          <td>{{ p.privilege_type }}</td>
          <td class="text-center">
            {{ p.granted ? '&check;' : '' }}
          </td>
          <td class="text-center">
            {{ p.is_grantable ? '&check;' : '' }}
          </td>
        </tr>
      </table>
    </div>
    <div class="yb-view-footer pt-4 border-t border-yb-gray-lightest dark:border-yb-gray-alt-lightest-inverse">
      <div class="flex flex-row justify-end">
        <yb-input
          v-if="$toggles.accessControlDebug"
          v-model="showDebug"
          type="checkbox"
          label="Toggle Debug"
          class="ml-8 text-sm whitespace-nowrap"
          width-class="w-auto"
        />

        <div class="button-group flex justify-end space-x-4">
          <yb-button label="Reset" class="yb-button-default-xl" data-test-id="object-privileges-reset" @click="reset" />
          <yb-button label="Change" class="yb-button-primary-xl" data-test-id="object-privileges-change" :disabled="!hasSchemaPrivilegesChange && changeCount == 0" @click="change" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import YbObjectPrivilegeEditorChiclets from './ObjectPrivilegeEditorChiclets.vue'
import YbObjectPrivilegeEditorGrid from './ObjectPrivilegeEditorGrid.vue'
import YbPrivilegeChiclet from './PrivilegeChiclet.vue'
import YbRoleSelector from './RoleSelector.vue'
import privilegeCategorizations from './PrivilegeCategorizations'
import Instance from '@/models/Instance'
import { capitalize } from '@/filters'
import * as functions from '@/util/functions'
import { databaseObjectService, errorHandler } from '@/services'

export default {
  components: {
    YbObjectPrivilegeEditorChiclets,
    YbObjectPrivilegeEditorGrid,
    YbPrivilegeChiclet,
    YbRoleSelector
  },
  props: {
    instanceId: {
      type: String,
      required: true
    },
    database: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: false
    },
    targets: {
      type: Array,
      required: true
    },
    description: {
      type: String,
      required: false
    },
    schema: {
      type: String,
      required: false
    }
  },
  data() {
    const owners = new Set(this.targets.map(target => target.owner))
    const owner = owners.size === 1 ? owners.values().next().value : null
    return {
      loading: false,
      storedPrivileges: [],
      selfPrivileges: [],
      roles: [],
      changes: [],
      storedOwner: owner,
      effectiveOwner: owner,
      effectiveOwners: owners,
      hasOwner: owners.size > 0,
      showDebug: false,
      privilegesManageBy: this.$store.get('settings/privilegesManageBy') || 'role',
      showSchemaPrivileges: false,
      schemaPrivileges: {
        action: null,
        type: null,
        privilege: null,
        roles: []
      },
      schemaObjectTypes: [
        {
          value: 'table',
          label: 'Tables/Views'
        },
        {
          value: 'sequence',
          label: 'Sequences'
        },
        {
          value: 'procedure',
          label: 'Procedures'
        }
      ],
      schemaObjectPrivileges: []
    }
  },
  computed: {
    editorComponent() {
      return this.privilegesManageBy === 'privilege' ? 'YbObjectPrivilegeEditorChiclets' : 'YbObjectPrivilegeEditorGrid'
    },
    instance() {
      return Instance.query().whereId(this.instanceId).first()
    },
    username() {
      return this.$store.get('global/settings@user.email')
    },
    userId() {
      return this.roleMapByName[this.username]?.id
    },
    ownername() {
      return this.effectiveOwner ? this.roleMapById[this.effectiveOwner]?.name : null
    },
    storedOwnername() {
      return this.storedOwner ? this.roleMapById[this.storedOwner]?.name : '(multiple)'
    },
    manageableRolesNoPublic() {
      const { ownername, username } = this
      return this.roles
        .filter(r => r.id >= 16384)
        .filter(r => !r.name.match(/^sys_ybd_/))
        .filter(r => r.name !== ownername)
        .filter(r => r.name !== username)
    },
    manageableRoles() {
      return this.manageableRolesNoPublic
        .concat(...[{ name: 'PUBLIC', id: 0 }])
    },
    rolesMap() {
      return functions.indexBy(this.roles, 'name')
    },
    storedPrivilegesMap() {
      const map = functions.groupBy(this.storedPrivileges, 'privilege_type')
      const result = {}
      this.selfPrivileges.forEach((p) => {
        if (!map[p.privilege_type]) {
          result[p.privilege_type] = []
        }
      })
      delete map.ALL
      delete result.ALL
      return { ...result, ...map }
    },
    storedPrivilegeUsers() {
      return functions.groupBy(this.storedPrivileges, 'grantee')
    },
    selfPrivilegesMap() {
      return functions.indexBy(this.selfPrivileges, 'privilege_type')
    },
    hasGrantablePrivileges() {
      return this.selfPrivileges.some(p => !!p.is_grantable)
    },
    allPrivilegeTypes() {
      return Array.from(new Set([...Object.keys(this.storedPrivilegesMap), ...Object.keys(this.selfPrivilegesMap)]))
        .filter(p => p !== 'PLACEHOLDER')
        .sort()
    },
    roleMapByName() {
      return functions.indexBy(this.roles, 'name')
    },
    roleMapById() {
      return functions.indexBy(this.roles, 'id')
    },
    changeCount() {
      return this.filteredChanges.length
    },
    filteredChanges() {
      return this.changes.filter(p => p.privilege_type !== 'PLACEHOLDER')
    },
    privilegeCategorization() {
      return privilegeCategorizations[this.type]
    },
    effectiveLabel() {
      return this.targets.map((target) => {
        const { label, object } = target
        return label || object
      }).join(', ')
    },
    hasSchemaPrivilegesChange() {
      return true &&
        !!this.showSchemaPrivileges &&
        !!this.schemaPrivileges.action &&
        !!this.schemaPrivileges.type &&
        !!this.schemaPrivileges.privilege &&
        !!this.schemaPrivileges.role
    }
  },
  watch: {
    privilegesManageBy(_) {
      this.$store.set('settings/privilegesManageBy', _)
    },
    'schemaPrivileges.type'(_) {
      if (_) {
        this.schemaPrivileges.privilege = null
        const privileges = databaseObjectService.getObjectPrivilegeNames(_)
        this.schemaObjectPrivileges = [{
          label: 'All',
          value: 'ALL PRIVILEGES'
        }].concat(...privileges.map(p => ({
          label: capitalize(p),
          value: p
        })))
      }
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    async load() {
      this.loading = true
      try {
        await databaseObjectService.connect(this.instance.id)
        const results = await Promise.all([
          databaseObjectService.getStoredObjectPrivileges(this.instance.id, this.database, this.type, this.targets.map(target => target.id)),
          databaseObjectService.getRoleObjectPrivileges(this.instance.id, this.database, this.type, this.targets.map(target => target.id)),
          databaseObjectService.populateRoles(this.database, true)
        ])
        const [storedPrivileges] = results
        this.storedPrivileges = storedPrivileges.map((p) => {
          return {
            ...p,
            ...{
              revoke: false,
              grant: false,
              starting_is_grantable: p.is_grantable
            }
          }
        });
        [, this.selfPrivileges, this.roles] = results
      } catch (e) {
        errorHandler.thrown(e, 'Could not retrieve object privilege information.')
      } finally {
        this.loading = false
      }
    },
    grantRole(privilege_type, role, is_grantable) {
      const { selfPrivilegesMap } = this
      const grant = { grantee: role, privilege_type, grantor: this.username, grant: true, revoke: false, is_grantable: !!is_grantable, starting_is_grantable: false }
      if (privilege_type !== 'ALL') {
        if (privilege_type === 'PLACEHOLDER' || !!selfPrivilegesMap[privilege_type]?.is_grantable) {
          this.storedPrivileges.splice(this.storedPrivileges.length, 0, grant)
          this.changes.splice(this.changes.length, 0, grant)
        } else {
          this.$dialogs.warning(`You do not have ${privilege_type} privilege to GRANT for this object.`)
        }
      } else {
        this.storedPrivileges = this.storedPrivileges.filter(p => p.grantee !== role)
        const storedPrivilegesLength = this.storedPrivileges.length
        this.allPrivilegeTypes
          .filter(privilege_type => !!selfPrivilegesMap[privilege_type]?.is_grantable)
          .forEach(privilege_type => this.storedPrivileges.splice(this.storedPrivileges.length, 0, { ...grant, ...{ privilege_type } }))
        if (storedPrivilegesLength !== this.storedPrivileges.length) {
          this.changes.splice(this.changes.length, 0, grant)
        } else {
          this.$dialogs.warning('You do not have privileges to GRANT ALL for this object.')
        }
      }
    },
    grantAll(role, is_grantable) {
      this.grantRole('ALL', role.name, is_grantable)
    },
    revokeAll(role) {
      this.storedPrivileges = this.storedPrivileges.filter(item => item.grantee !== role.name)
      this.allPrivilegeTypes.forEach(privilege_type => this.removeChange({ privilege_type, grantee: role.name }))
      const revoke = { grantee: role.name, privilege_type: 'ALL', grantor: this.username, grant: false, revoke: true, is_grantable: false, starting_is_grantable: false }
      this.changes = this.changes.filter(item => item.grantee !== role.name)
      this.changes.splice(this.changes.length, 0, revoke)
    },
    toggleRevoke(grantee, privilege_type) {
      const itemIndex = this.storedPrivileges.findIndex(p => p.grantee === grantee && p.privilege_type === privilege_type)
      if (itemIndex >= 0) {
        const item = this.storedPrivileges[itemIndex]
        this.removeChange(item)
        if (item.grant) {
          this.storedPrivileges.splice(itemIndex, 1)
          const removeChangeIndex = this.changes.findIndex(p => p.grantee === grantee && p.privilege_type === privilege_type)
          if (removeChangeIndex >= 0) {
            this.changes.splice(removeChangeIndex, 1)
          }
        } else {
          item.revoke = !item.revoke
          if (item.revoke) {
            item.grant = false
            item.is_grantable = false
          }
          this.changes.splice(this.changes.length, 0, item)
        }
      }
    },
    toggleWithGrant(item, is_grantable) {
      item.is_grantable = is_grantable
      if (item.is_grantable) {
        item.grant = true
      }
      this.removeChange(item, true)
      this.changes.splice(this.changes.length, 0, item)
    },
    async removeChange(item, ignoreAllChange) {
      const removeChangeIndex = this.changes.findIndex(p => p.grantee === item.grantee && (p.privilege_type === item.privilege_type || (!ignoreAllChange && p.privilege_type === 'ALL')))
      if (removeChangeIndex >= 0) {
        const change = this.changes[removeChangeIndex]
        this.changes.splice(removeChangeIndex, 1)
        if (change.privilege_type === 'ALL') {
          // Ask the user if we should remove privileges gramted by ALL.
          const storedPrivilegesForGrantee = this.storedPrivileges.filter(p => p.grantee === item.grantee && p.privilege_type !== item.privilege_type)
          if (storedPrivilegesForGrantee.length > 0) {
            try {
              await this.$dialogs.confirm(
                `There are ${storedPrivilegesForGrantee.length} privileges granted from "ALL" to this role (<strong>${item.grantee}</strong>).<br><br>Choose Remove to remove these privileges or Keep to leave the privileges granted.`,
                'Remove',
                'Keep')
              this.storedPrivileges = this.storedPrivileges.filter(p => p.grantee !== item.grantee)
            } catch (cancel) {
              // Add back the stored privileges as changes here.
              storedPrivilegesForGrantee.forEach((p) => {
                this.changes.splice(this.changes.length, 0, p)
              })
            }
          }
        }
      }
    },
    changeOwner(role) {
      this.effectiveOwner = role.id
      this.effectiveOwners = new Set([role.id])
      this.changedOwner = true
      const ownerChangeIndex = this.changes.findIndex(p => !!p.owner)
      if (ownerChangeIndex >= 0) {
        this.changes.splice(ownerChangeIndex, 1)
      }
      this.changes.splice(this.changes.length, 0, { owner: true, grantee: role.name })
    },
    getSql() {
      const objects = this.targets.map(target => target.object)
      const quotedObjects = objects.map(object => object ? (!object.match(/^"/) ? `"${object}"` : object) : '').join(', ')
      let sql = this.filteredChanges.map((p) => {
        const quotedGrantee = p.grantee === 'PUBLIC' ? 'PUBLIC' : `"${p.grantee}"`
        const objectType = this.type.toUpperCase().replace(/_/g, ' ')
        if (p.owner) {
          return objects.map((object) => {
            const quotedObject = object ? (!object.match(/^"/) ? `"${object}"` : object) : ''
            return `ALTER ${objectType} ${quotedObject} OWNER TO ${quotedGrantee}`
          }).join(';\n')
        } else {
          const statements = []
          if (!!p.revoke || (!p.is_grantable && p.is_grantable !== p.starting_is_grantable)) {
            statements.push(`REVOKE ${p.privilege_type} ON ${objectType} ${quotedObjects} FROM ${quotedGrantee}`)
          }
          if (!!p.grant || (!p.revoke && p.is_grantable !== p.starting_is_grantable)) {
            statements.push(`GRANT ${p.privilege_type} ON ${objectType} ${quotedObjects} TO ${quotedGrantee}${!!p.is_grantable && p.grantee !== 'PUBLIC' ? ' WITH GRANT OPTION' : ''}`)
          }

          return statements.length ? statements.join(';\n') : null
        }
      }).filter(s => !!s).join(';\n')
      if (sql) {
        sql += ';\n'
      }
      return sql
    },
    getSchemaPrivilegesSql() {
      const objects = this.targets.map(target => target.object)
      const quotedObjects = this.schema ? `"${this.schema}"` : objects.map(object => object ? (!object.match(/^"/) ? `"${object}"` : object) : '').join(', ')
      const role = this.schemaPrivileges.role === 'PUBLIC' ? this.schemaPrivileges.role : `"${this.schemaPrivileges.role}"`
      return `${this.schemaPrivileges.action.toUpperCase()} ${this.schemaPrivileges.privilege.toUpperCase()}
ON ALL ${this.schemaPrivileges.type.toUpperCase()}S
IN SCHEMA ${quotedObjects}
${this.schemaPrivileges.action === 'grant' ? 'TO' : 'FROM'} ${role}`
    },
    async reset() {
      this.changes = []
      this.storedPrivileges = []
      this.changedOwner = false
      this.showSchemaPrivileges = false
      await this.load()
    },
    async change() {
      try {
        await databaseObjectService.connect(this.instance.id)
        const results = await databaseObjectService.sql(this.instance.id, this.database, this.hasSchemaPrivilegesChange ? this.getSchemaPrivilegesSql() : this.getSql())
        if (results?.rows?.length) {
          console.log('Changes made: ', results.rows)
        }
        if (this.changedOwner) {
          this.storedOwner = this.effectiveOwner
          this.effectiveOwners = new Set([this.storedOwner])
        }
        this.changedOwner = false
        if (this.targets.length === 1) {
          this.$dialogs.info('Privileges for this object were updated.')
        } else {
          this.$dialogs.info('Privileges for these objects were updated.')
        }
        this.reset()
      } catch (e) {
        errorHandler.thrown(e, 'Could not save object privileges.')
      } finally {
        this.loading = false
      }
    },
    capitalize,
    manageSchemaPrivileges() {
      this.showSchemaPrivileges = true
      this.schemaPrivileges.action = null
      this.schemaPrivileges.type = null
      this.schemaPrivileges.privilege = null
      this.schemaPrivileges.roles = null
    }
  }
}
</script>

<style lang="postcss" scoped>
div.object-editor :deep(.vue-tabpanel) {
  @apply pt-8;
}
</style>
